*,
*::after,
*::before {
  box-sizing: border-box !important;
}

:root {
  font-family: 'Montserrat', sans-serif;
  line-height: 1.5;
  font-weight: 400;

  color-scheme: light dark;
  color: #252525;
  background-color: #fefefe;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fefefe;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #252525;
}

a {
  font-weight: 500;
  color: #646cff;
  text-decoration: inherit;
}
a:hover {
  color: #535bf2;
}

img {
  max-width: 100%;
}

body {
  padding: 0;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  overscroll-behavior-y: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

