button[data-type="prev"] {
    left: -50px !important;
}

button[data-type="next"] {
    right: -50px !important;
}

@media (max-width: 768px) {
    button[data-type="prev"] {
        left: -30px !important;
    }

    button[data-type="next"] {
        right: -30px !important;
    }
}